/**
 * Privacy page
 */
/* Import section */
import * as React from "react"
import { graphql } from "gatsby"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Seo from "../components/seo"

/* Declaration */
const PrivacyPage = ({data}) => {
  //Const data
  const dataPage = data.wpPage
  const tLinkSlug = "/"

  return(
    <Layout tLinkSlug={tLinkSlug}>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        tLinkSlug={tLinkSlug}
         />
      <section className="section-hero-general section-herotype2">
        <Herotype2 h1title="Privacy Policy" />
      </section>

      <section>
        <div className="wrap-centercontent">
          <div className="centercontent general" dangerouslySetInnerHTML={{__html:dataPage.content}} />
        </div>
      </section>
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpPage(slug: {regex: "/privacy-policy/"}) {
      content
      seo {
        metaDesc
        title
      }
    }
  }
`

export default PrivacyPage
